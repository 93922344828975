
export default{
    Home:{
        name:"Hey, I'm Xiong xu",
        introduction:'a unknown',
        job:'front-end developer from China',
        goodMorning:'Have a good morning',
        goodAfternoon:'Have a good afternoon',
        goodEvening:'Have a good evening',
        goodNight:'Have a good night',
        goodWeekend:'Have a good Weekend',
        nav_home:'Home',
        nav_about:'About',
        nav_project:'Project',
        nav_Resume:'Resume',
        meta_name:'Maksim Hsu',
        meta_city:'Shanghai',
        meta_country:'China',
        meta_job:'front-end engineer',
        ToggleTheme:'Toggle theme',
        ToggleLang:'Toggle language',
    },
    Project:{
        portfolio_desc:'This personal website is built with React framework to enable component-based development, and uses Material UI design system to create aesthetically pleasing UI quickly.The website content fully demonstrates personal information, and the responsive web design ensures great browsing experience across devices.'
    },
    About:{
        title:"About me",
        introduction_job:'Hi,there.I am a front-end developer with more than 3 years of working experience. I am currently working in an Internet company in Shanghai, mainly responsible for the front-end development work within the company. ',
        introduction_like:'I like some cool things, and I like to study new technologies and share new technologies.',
        introduction_skill:'I specialize in using',
        introduction_build:'to craft eye-catching websites, always aiming for user-friendly online experiences.',
        introduction_spare:'Beyond my professional work, I have a passion for creating cool websites using',
        introduction_F_project:' I love translating creative ideas into digital creations with unique designs. (Like this portfolio)',
        introduction_contact:'Apart from my work, I am also excited about connecting with like-minded individuals who share the same interests. If you are looking for an experienced and enthusiastic developer, I am excited to collaborate and create stunning web experiences together！',
    }
}   